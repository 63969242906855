/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from '../assets/me.png';
import twitter from '../assets/x.png';
import tg from '../assets/tg.png';
import logo from '../assets/3.jpg';
import logoUp from '../assets/Logo-up.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import { sepolia } from 'wagmi/chains'
import Footer from '../Components/footer';

const ops = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const homeLink = () => {
    window.open("#");
}

let ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__InvalidTransferValidatorContract",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__SetTransferValidatorFirst",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintERC2309QuantityExceedsLimit",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterCannotBeZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterHasAlreadyBeenAssignedToTokenId",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__OnlyMinterCanChangeRoyaltyFee",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeGreaterThanMaximumAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeWillExceedSalePrice",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnershipNotInitializedForExtraData",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ShouldNotMintToBurnAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "fromTokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "toTokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "ConsecutiveTransfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint96",
                "name": "feeNumerator",
                "type": "uint96"
            }
        ],
        "name": "RoyaltySet",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "oldValidator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newValidator",
                "type": "address"
            }
        ],
        "name": "TransferValidatorUpdated",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CREATOR_MAX_ROYALTY",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_OPERATOR_WHITELIST_ID",
        "outputs": [
            {
                "internalType": "uint120",
                "name": "",
                "type": "uint120"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_SECURITY_LEVEL",
        "outputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_VALIDATOR",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "FEE_DENOMINATOR",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ROYALTY_PERCENT",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "baseTokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "defaultRoyaltyFeeNumerator",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getPermittedContractReceivers",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSecurityPolicy",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "enum TransferSecurityLevels",
                        "name": "transferSecurityLevel",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint120",
                        "name": "operatorWhitelistId",
                        "type": "uint120"
                    },
                    {
                        "internalType": "uint120",
                        "name": "permittedContractReceiversId",
                        "type": "uint120"
                    }
                ],
                "internalType": "struct CollectionSecurityPolicy",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenKey",
                "type": "uint256"
            }
        ],
        "name": "getTokenMinterAward",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTransferValidator",
        "outputs": [
            {
                "internalType": "contract ICreatorTokenTransferValidator",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getWhitelistedOperators",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "isContractReceiverPermitted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isOperatorWhitelisted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "caller",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "isTransferAllowed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokenPurchase",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokens",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_numberOfTokens",
                "type": "uint256"
            }
        ],
        "name": "publicMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "remainingSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_reserveAmount",
                "type": "uint256"
            }
        ],
        "name": "reserveTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "royaltyAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMax",
                "type": "uint256"
            }
        ],
        "name": "setMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMaxTokenPurchase",
                "type": "uint256"
            }
        ],
        "name": "setMaxTokenPurchase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newPrice",
                "type": "uint256"
            }
        ],
        "name": "setPublicMintPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint96",
                "name": "royaltyFeeNumerator",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "validator",
                "type": "address"
            },
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomValidatorAndSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setToDefaultSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "tokenKeys",
                "type": "uint256[]"
            },
            {
                "internalType": "string[]",
                "name": "minterAward",
                "type": "string[]"
            }
        ],
        "name": "setTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newTokenReserve",
                "type": "uint256"
            }
        ],
        "name": "setTokenReserve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "transferValidator_",
                "type": "address"
            }
        ],
        "name": "setTransferValidator",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "togglePublicMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsImmutable",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenReserve",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawBalance",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

let address = "0xf0bDc91Da79E6fC8f1E3ad6Bdfd64548d3656690";

const maxSupply = 4444

const Awards = () => {

    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })

    const publicClient = createPublicClient({
        chain: sepolia,
        transport: http()
    })

    const [_totalSupply, settotalSupply] = useState("")
    const [statusError, setstatusError] = useState("")
    const [statusLoading, setstatusLoading] = useState("")
    const [success, setsuccess] = useState("")
    const [nftMintingAmount, setnftMintingAmount] = useState(2)
    const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
    const [_cost, set_publicSaleCost] = useState("")
    const [_max_per_wallet, set_max_per_wallet] = useState(20)
    const [_owner, set_owner] = useState("")
    const [myNFTWallet, setmyNFTWallet] = useState("")
    const [_pause, set_pause] = useState(false)
    const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
    const [_maxFreeSupply, set_maxFreeSupply] = useState("")
    const [_freeAmount, set_freeAmount] = useState("")
    const [_userBalance, set_userBalance] = useState("")
    const [_a1, set_a1] = useState(0)
    const [_a2, set_a2] = useState(0)
    const [_a3, set_a3] = useState(0)
    const [_a4, set_a4] = useState(0)
    const [_a5, set_a5] = useState(0)
    const [_a6, set_a6] = useState(0)
    const [_btnAwards, set_btnAwards] = useState(0)
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [tokenID, setTokenID] = useState('');
    const [rewards, setRewards] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [_supply, setSupply] = useState(false);
    const [msg, setMsg] = useState('');


    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const contract = {
        address: address,
        abi: ABI
    }

    async function handleConnect() {
        if (chain.id !== 11155111) {
            switchNetwork(11155111)
        }
        var data = await getBalance()
        setmyNFTWallet(data.data)
        console.log("myNFTWallet :" + data.data)
    }

    useEffect(() => {
        if (myNFTWallet === 0) {
            setnftMintingAmount(2)
        }


        async function fetchSupply() {
            var data2 = await getSupply();

            setSupply(Number(data2.data));
            console.log("setSupply : " + Number(data2.data));

        }

        /* if (tokenID > 0) {
             fetchRewards();
         }*/

        fetchSupply();
       
    }, [myNFTWallet, tokenID])

    
        const handleInputChange = (event) => {
            const value = event.target.value;
            // Check if the value is not equal to 0 and does not exceed 10000
            if (value !== '0' && value <= 10000) {
                setTokenID(value);
            } else if (value > 10000) {
                setTokenID(10000);
            } else {
                setTokenID(''); // Reset the tokenID if it is 0
            }
        };
    

    const { refetch: getBalance } = useContractRead({
        ...contract,
        functionName: 'balanceOf',
        args: [walletAddress ? walletAddress : '0x']
    })


    const { refetch: getRewards } = useContractRead({
        ...contract,
        functionName: 'getTokenMinterAward',
        args: [tokenID]
    })

    const { refetch: getSupply } = useContractRead({
        ...contract,
        functionName: 'totalSupply',
        args: []
    })

    const { writeAsync } = useContractWrite({
        ...contract,
        functionName: 'mint',
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })

    
    const fetchRewards = async () => {
        try {
            const data2 = await getRewards();

            setRewards(data2.data);
            setMsg(`${data2.data}`);
            console.log("msg Read : " + data2.data);

            set_btnAwards(1);

        } catch (error) {
            console.log(error.message)
            // setMsg(`Error fetching rewards: ${error.message}`);
        }
    };

    async function closediv() {
        set_a1(0);
        set_a2(0);
        set_a3(0);
        set_a4(0);
        set_a5(0);
        set_a6(0);
    }

    return (
        <div class="allWrap2">
            <div class="light2">
                <div class="cont">
                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div class="icons">
                                <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                <div className='iconsMain'>
                                    <div class="socialIcon"><img onClick={ops} src={os} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={tg} /></div>
                                </div>
                            </div>

                        </div>) : null}

                    <div class="headers">

                        <div class="headers2">

                            <div class="logo" onClick={() => window.location.href = '/'}>
                                <img class="logoPic" src={logo} />
                                <img class="logoUp" src={logoUp} />

                            </div>

                            <div class="right">

                                <div class="icons">
                                    <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                    <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                    <div class="socialIcon"><img onClick={ops} src={os} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={tg} /></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='inputMain'>
                        <div className='inputMainSub'>
                            <div className='inputT'>View Token Minter Awards</div>
                            <input
                                type='number'
                                placeholder='Token Key'
                                value={tokenID}
                                onChange={handleInputChange}
                            />
                        </div>

                        <button onClick={fetchRewards} className='submitBtn'>Check</button>

                        {tokenID !== '' && _btnAwards > 0 &&
                            (msg === "undefined" && _btnAwards > 0 ?
                                <div className='msg2'>
                                    The token entered has not been minted yet so we are unable to confirm any award
                                </div> :
                                <div className='msg'>{msg}</div>
                            )
                        }

                    </div>


                </div>

            </div >
            <Footer />

        </div >
    )

}
export default Awards;
