/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from '../assets/me.png';
import twitter from '../assets/x.png';
import tg from '../assets/tg.png';
import logo from '../assets/3.jpg';
import pic1 from '../assets/8.jpg';
import pic2 from '../assets/6.jpg';
import pic3 from '../assets/9.jpg';
import pic4 from '../assets/1.jpg';
import pic5 from '../assets/2.jpg';
import pic6 from '../assets/7.jpg';
import flower2 from '../assets/flower2.gif';
import rocket from '../assets/rocket.gif';
import heart from '../assets/heart.gif';
import song from '../assets/song.gif';
import tea from '../assets/tea.gif';
import wand from '../assets/magic-wand.png';
import logoUp from '../assets/Logo-up.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import Carousel from '../Components/carousel';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import { sepolia } from 'wagmi/chains'
import Footer from '../Components/footer';

const ops = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const homeLink = () => {
    window.open("#");
}

let ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__InvalidTransferValidatorContract",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__SetTransferValidatorFirst",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintERC2309QuantityExceedsLimit",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterCannotBeZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterHasAlreadyBeenAssignedToTokenId",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__OnlyMinterCanChangeRoyaltyFee",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeGreaterThanMaximumAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeWillExceedSalePrice",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnershipNotInitializedForExtraData",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ShouldNotMintToBurnAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "fromTokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "toTokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "ConsecutiveTransfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint96",
                "name": "feeNumerator",
                "type": "uint96"
            }
        ],
        "name": "RoyaltySet",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "oldValidator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newValidator",
                "type": "address"
            }
        ],
        "name": "TransferValidatorUpdated",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CREATOR_MAX_ROYALTY",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_OPERATOR_WHITELIST_ID",
        "outputs": [
            {
                "internalType": "uint120",
                "name": "",
                "type": "uint120"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_SECURITY_LEVEL",
        "outputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_VALIDATOR",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "FEE_DENOMINATOR",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ROYALTY_PERCENT",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "baseTokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "defaultRoyaltyFeeNumerator",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getPermittedContractReceivers",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSecurityPolicy",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "enum TransferSecurityLevels",
                        "name": "transferSecurityLevel",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint120",
                        "name": "operatorWhitelistId",
                        "type": "uint120"
                    },
                    {
                        "internalType": "uint120",
                        "name": "permittedContractReceiversId",
                        "type": "uint120"
                    }
                ],
                "internalType": "struct CollectionSecurityPolicy",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenKey",
                "type": "uint256"
            }
        ],
        "name": "getTokenMinterAward",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTransferValidator",
        "outputs": [
            {
                "internalType": "contract ICreatorTokenTransferValidator",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getWhitelistedOperators",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "isContractReceiverPermitted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isOperatorWhitelisted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "caller",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "isTransferAllowed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokenPurchase",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokens",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_numberOfTokens",
                "type": "uint256"
            }
        ],
        "name": "publicMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "remainingSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_reserveAmount",
                "type": "uint256"
            }
        ],
        "name": "reserveTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "royaltyAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMax",
                "type": "uint256"
            }
        ],
        "name": "setMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMaxTokenPurchase",
                "type": "uint256"
            }
        ],
        "name": "setMaxTokenPurchase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newPrice",
                "type": "uint256"
            }
        ],
        "name": "setPublicMintPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint96",
                "name": "royaltyFeeNumerator",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "validator",
                "type": "address"
            },
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomValidatorAndSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setToDefaultSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "tokenKeys",
                "type": "uint256[]"
            },
            {
                "internalType": "string[]",
                "name": "minterAward",
                "type": "string[]"
            }
        ],
        "name": "setTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newTokenReserve",
                "type": "uint256"
            }
        ],
        "name": "setTokenReserve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "transferValidator_",
                "type": "address"
            }
        ],
        "name": "setTransferValidator",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "togglePublicMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsImmutable",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenReserve",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawBalance",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

let address = "0xf0bDc91Da79E6fC8f1E3ad6Bdfd64548d3656690";

const maxSupply = 4444

const Home = () => {

    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })

    const publicClient = createPublicClient({
        chain: sepolia,
        transport: http()
    })

    const [_totalSupply, settotalSupply] = useState("")
    const [statusError, setstatusError] = useState("")
    const [statusLoading, setstatusLoading] = useState("")
    const [success, setsuccess] = useState("")
    const [nftMintingAmount, setnftMintingAmount] = useState(2)
    const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
    const [_cost, set_publicSaleCost] = useState("")
    const [_max_per_wallet, set_max_per_wallet] = useState(20)
    const [_owner, set_owner] = useState("")
    const [myNFTWallet, setmyNFTWallet] = useState("")
    const [_pause, set_pause] = useState(false)
    const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
    const [_maxFreeSupply, set_maxFreeSupply] = useState("")
    const [_freeAmount, set_freeAmount] = useState("")
    const [_userBalance, set_userBalance] = useState("")
    const [_a1, set_a1] = useState(0)
    const [_a2, set_a2] = useState(0)
    const [_a3, set_a3] = useState(0)
    const [_a4, set_a4] = useState(0)
    const [_a5, set_a5] = useState(0)
    const [_a6, set_a6] = useState(0)
	const [_navbarOpen, set_navbarOpen] = useState(0);

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

    const contract = {
        address: address,
        abi: ABI
    }

    async function handleConnect() {
        if (chain.id !== 111155111) {
            switchNetwork(11155111)
        }
        var data = await getBalance()
        setmyNFTWallet(data.data)
        console.log("myNFTWallet :" + data.data)
    }

    function onPlus() {

        console.log("_pause :" + _pause);

        var nftIhave = BigInt(nftMintingAmount) + BigInt(myNFTWallet);
        console.log("nftIhave : " + nftIhave);

        if (!_pause) {

            if (nftIhave < _max_per_wallet) {

                setnftMintingAmount(nftMintingAmount + 1);

            }
        }

    }

    function onMinus() {

        console.log("1");
        var testM;
        testM = nftMintingAmount - 1;
        console.log("2");

        setnftMintingAmount(testM)
        console.log("3");

        if (testM < 2) {
            console.log("4");

            console.log("myNFTWallet : " + myNFTWallet);
            if (myNFTWallet == 0) {
                console.log("5");

                setnftMintingAmount(2);
                console.log("6");

            } else if (testM < 1) {
                console.log("7");

                setnftMintingAmount(1);
                console.log("8");

            }
            console.log("9");

        }

        console.log("10");

        console.log("testM : " + testM);

        /*if (nftMintingAmount != 1) {
            console.log("testM : " + testM);
            setnftMintingAmount(nftMintingAmount - 1)

            if (myNFTWallet === 0 && nftMintingAmount < 3) {
                setnftMintingAmount(nftMintingAmount + 1)
            }
        }*/



    }

    const { data, refetch, isSuccess } = useContractReads({
        contracts: [
            { ...contract, functionName: 'totalSupply' },
            { ...contract, functionName: 'maxPerWallet' },
            { ...contract, functionName: 'owner' },
            { ...contract, functionName: 'cost' },
            { ...contract, functionName: 'maxSupply' },
            { ...contract, functionName: 'pause' },
            { ...contract, functionName: 'maxFreeSupply' },
            { ...contract, functionName: 'freeAmount' },
            { ...contract, functionName: 'userBalance', args: [walletAddress ? walletAddress : '0x'] }



        ]
    },)

    useMemo(() => {
        if (isSuccess === true) {
            settotalSupply(Number(data[0].result))
            set_max_per_wallet(data[1].result)
            set_owner(data[2].result)
            set_publicSaleCost(Number(data[3].result) / 10 ** 18)
            set_MAX_SUPPLY(data[4].result)
            set_pause(data[5].result)
            set_maxFreeSupply(data[6].result)
            set_freeAmount(data[7].result)
            set_userBalance(data[8].result)
            console.log("pause+ : " + _pause);
        }
    }, [isSuccess])


    useEffect(() => {
        if (myNFTWallet === 0) {
            setnftMintingAmount(2)
        }
    }, [myNFTWallet])

    const { refetch: getBalance } = useContractRead({
        ...contract,
        functionName: 'balanceOf',
        args: [walletAddress ? walletAddress : '0x']
    })

    console.log(data)

    const { writeAsync } = useContractWrite({
        ...contract,
        functionName: 'mint',
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })

    async function onMint() {

        var mintCost;

        if (!_pause) {
            mintCost = parseFloat(_cost)
        }

        console.log("_userBalance : " + _userBalance);
        console.log("nftMintingAmount : " + nftMintingAmount);

        if ((_userBalance) < 2) {
            mintCost = ((nftMintingAmount - (Number(_freeAmount) - Number(_userBalance))) * mintCost).toString();
            console.log("mintCost1 : " + mintCost);
        } else {
            //mintCost = (mintCost * nftMintingAmount).toString()
            mintCost = (mintCost * nftMintingAmount).toString()
            console.log("mintCost2 : " + mintCost);

        }


        try {
            setstatusLoading(true)
            setstatusError(false)

            var res = await writeAsync({
                args: [nftMintingAmount],
                value: parseEther(mintCost)
            })
            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
            }
            else {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
            }
            await new Promise(resolve => setTimeout(resolve, 5000));
            window.location.reload(true);
        }
        catch (e) {
            console.log(e)
            setstatusError(true)
            setstatusLoading(false)
        }
    }

    async function closediv() {
        set_a1(0);
        set_a2(0);
        set_a3(0);
        set_a4(0);
        set_a5(0);
        set_a6(0);
    }

    async function a1() {
        set_a1(1);
        console.log("popupdiv Open")
    }

    async function a2() {
        set_a2(1);
        console.log("popupdiv Open")
    }

    async function a3() {
        set_a3(1);
        console.log("popupdiv Open")
    }

    async function a4() {
        set_a4(1);
        console.log("popupdiv Open")
    }

    async function a5() {
        set_a5(1);
        console.log("popupdiv Open")
    }

    async function a6() {
        set_a6(1);
        console.log("popupdiv Open")
    }

    return (
        <div class="allWrap">
            <div class="light2">
                <div class="cont">
                {_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>
							
							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>
					
							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					{_navbarOpen > 0 ?
						(<div class="littleNav">
							<div class="icons">
                            <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                            <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
								<div className='iconsMain'>
								<div class="socialIcon"><img onClick={ops} src={os} /></div>
								<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
								<div class="socialIcon"><img onClick={tweet} src={tg} /></div>
							</div>
							</div>

						</div>) : null}

                    <div class="headers">

                        <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                                <img class="logoPic" src={logo} />
                                <img class="logoUp" src={logoUp} />

                            </div>

                            <div class="right">

                                <div class="icons">
                                    <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                    <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
                                    <div class="socialIcon"><img onClick={ops} src={os} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={tg} /></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='faqMain'>
                        <div className='sectionMain'>
                            <div className='section1'>
                                {/*<img className='nftFAQ' src={pic1} />*/}
                                <div>How can I make these adorable Cockadoodles mine?</div>
                                <img className='wand' onClick={a1} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>What makes these little feathered friends so special?</div>
                                <img className='wand' onClick={a2} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Are there only a limited number of these cuties to go around?</div>
                                <img className='wand' onClick={a3} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Can I give my Cockadoodle a cozy new home if I decide to part ways?</div>
                                <img className='wand' onClick={a4} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Do these charming Cockadoodles come with any extra goodies?</div>
                                <img className='wand' onClick={a5} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Can I show off my collection to my pals and spread the Cockadoodle love?</div>
                                <img className='wand' onClick={a6} src={wand} />
                            </div>

                            

                            {_a1 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={flower2} alt="Flower" /></div>
                                    </div>
                                </div> : null}

                                {_a2 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup2">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={rocket} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                                {_a3 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup3">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={heart} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                                {_a4 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup4">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={song} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                                {_a5 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup5">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={tea} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                                {_a6 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup6">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={flower2} alt="Flower" /></div>
                                    </div>
                                </div> : null}


                        </div>
                    </div>

                </div>

                <Footer />                    

            </div >
        </div >
    )

}
export default Home;
